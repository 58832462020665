import "@aws-amplify/ui-react/styles.css";
import { Card, ThemeProvider, createTheme } from "@aws-amplify/ui-react";
import { LeadFormForSampleTestCreateForm } from "../ui-components";
// import LeadForm from "../ui-components/LeadForm";
// import { LeadFormCreateForm } from "../ui-components";
// import LeadFormCreateForm from "../ui-components/LeadFormCreateForm";

// import { MyForm } from "../ui-components";
// import { MyForm } from "../ui-components";

export const LeadFormComponent=()=>{
    const styles = {
       
        Card: {
          width: '40%',
         margin: 'auto',
          backgroundColor: 'rgba(255,255,255,0.48)',
          borderRadius: '16px',
          boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
        },
    }

    return (
        <>
        <ThemeProvider>
        <Card style={styles.Card} textAlign={'left'}>

        {/* <LeadForm onSubmit={(fields: any) => console.log("form data-----------------",fields)} /> */}
        {/* <LeadFormCreateForm onSubmit={(fields:any)=>{
            console.log("fields------->",fields)
            return fields;
        }}/> */}
        <LeadFormForSampleTestCreateForm onSubmit={(field)=>{
            return field;
        }}/>
        </Card>
        </ThemeProvider>
        </>
    )
}